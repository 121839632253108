export type MediaContentType = {
    type: string | 'video' | 'img';
    url: string;
    source?: string;
}
type MediaOptionsType = {
    imagesIds?: number[];
    videos?: { url: string }[];
}

export function concatMediaContent () {
  function initMediaArrays (options: MediaOptionsType) {
    const isVideo = Boolean(options.videos && options.videos.length);
    const isImage = Boolean(options.imagesIds && options.imagesIds.length);

    const contentImg: MediaContentType[] = [];
    const contentVideo: MediaContentType[] = [];

    if (isVideo) {
            options.videos!.forEach((t) => {
              contentVideo.push({
                type: 'video',
                url: `https://www.youtube.com/embed/${t.url}?wmode=opaque&autoplay=0&enablejsapi=1&modestbranding=1&showinfo=0&rel=0&controls=1`,
                source: t.url
              });
            });
    }

    if (isImage) {
            options.imagesIds!.forEach((t) => {
              contentImg.push({
                type: 'img',
                url: String(t)
              });
            });
    }

    return {
      contentImg,
      contentVideo
    };
  }

  function concatArrays (array1: MediaContentType[], array2: MediaContentType[]) {
    const result: MediaContentType[] = [];

    for (let i = 0; i < Math.max(array1.length, array2.length); i += 1) {
      if (i < array1.length) {
        result.push(array1[i]);
      }
      if (i < array2.length) {
        result.push(array2[i]);
      }
    }
    return result;
  }

  function getLinkForPreview (source: string) {
    return `https://img.youtube.com/vi/${source}/1.jpg`;
  }

  return {
    concatArrays,
    initMediaArrays,
    getLinkForPreview
  };
}
